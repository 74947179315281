import { CommonModule } from '@angular/common';
import { Component, computed, inject, Input, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subject, takeUntil, tap } from 'rxjs';

import { AudioSelectComponent } from 'src/app/features/av-check/components/audio-select/audio-select.component';
import { VideoSelectionComponent } from 'src/app/features/av-check/components/video-selection/video-selection.component';

import { AudioVideoCheckActions } from 'src/app/features/av-check/store';
import { AudioVideoCheckSelectors } from 'src/app/features/av-check/store/selectors';

import { NavigatorMediaDeviceWrapperService } from 'src/app/features/audio-video-connection-monitor/services';
import { CheckInActions } from 'src/app/features/check-in';

import { SharedModule } from 'src/app/features/shared';

import { AudioVideoCheckService } from 'src/app/features/av-check/services/audio-video-check.service';
import { SpeakerCheckService } from 'src/app/features/av-check/services/speaker-check.service';
import { MicCheckService } from '../../services/mic-check.service';

export enum DeviceType {
  Microphone = 'microphone',
  Camera = 'camera',
}

@Component({
  selector: 'app-audio-video-check-v2',
  standalone: true,
  imports: [AudioSelectComponent, CommonModule, SharedModule, VideoSelectionComponent],
  providers: [SpeakerCheckService],
  templateUrl: './audio-video-check-v2.component.html',
  styleUrl: './audio-video-check-v2.component.scss',
})
export class AudioVideoCheckV2Component implements OnDestroy {
  @Input() useDefaultActions = true;

  private readonly store = inject(Store);
  private readonly navigatorMediaDeviceWrapper = inject(NavigatorMediaDeviceWrapperService);
  private readonly destroying$ = new Subject();

  audioVideoCheckService = inject(AudioVideoCheckService);
  speakerCheckService = inject(SpeakerCheckService);
  micCheckService = inject(MicCheckService);

  areRequiredDevicesSelectedAndTested = computed(
    () =>
      (this.speakerCheckService.hasSpeakerFeature()
        ? this.store.selectSignal(AudioVideoCheckSelectors.selectAreInputAndOutputDevicesSelected)()
        : this.store.selectSignal(AudioVideoCheckSelectors.selectAreInputDevicesSelected)()) &&
      !this.audioVideoCheckService.hasUserMediaAudioFailure() && !this.audioVideoCheckService.hasUserMediaVideoFailure() &&
      this.audioVideoCheckService.mediaStream() && !this.micCheckService.micFailure() && this.micCheckService.selectAudioInputDevices().length !== 0
  );

  constructor() {
    this.store.dispatch(AudioVideoCheckActions.AudioVideoCheckOpened());

    this.navigatorMediaDeviceWrapper
      .onDeviceChange()
      .pipe(
        takeUntil(this.destroying$),
        tap(() => {
          this.store.dispatch(AudioVideoCheckActions.ClearVideo());
          this.store.dispatch(AudioVideoCheckActions.ClearMicrophone());
          this.store.dispatch(AudioVideoCheckActions.ClearSpeaker());

          this.store.dispatch(AudioVideoCheckActions.GetUserMediaAudio());
          this.store.dispatch(AudioVideoCheckActions.GetUserMediaVideo());
        })
      )
      .subscribe();
  }

  continue() {
    this.store.dispatch(AudioVideoCheckActions.SaveAuditSessionCapable());
    this.store.dispatch(CheckInActions.ContinueCheckInOrRejoin());
  }

  ngOnDestroy() {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
