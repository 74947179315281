import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal, WritableSignal } from '@angular/core';

import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';

import { DeviceGroupSelectors } from 'src/app/features/device-group/store/selectors';
import * as GadgetGuideActions from 'src/app/features/gadget-guide/store/actions/gadget-guide.actions';
import * as GadgetGuideSelectors from 'src/app/features/gadget-guide/store/selectors/gadget-guide.selectors';

enum BrowserFilterType {
  Chrome = 'Chrome',
  Edge = 'Edge',
}

interface Brand {
  brand: string;
  version: string;
}

export enum GadgetType {
  Camera = 'Camera',
  Microphone = 'Microphone',
  Speaker = 'Speaker',
}

export interface GadgetAgent {
  browserType: string;
  browserVersion: string;
  deviceModel: string;
  deviceName: string;
  osType: string;
  osVersion: string;
  gadgetType: GadgetType;
}

export interface GadgetGuideRequest {
  gadgetAgent: GadgetAgent;
  response: string;
  status: string;
  error: string;
}

export interface GadgetGuide {
  [sha256: string]: GadgetGuideRequest;
}

@Injectable({ providedIn: 'root' })
export class GadgetGuideService {
  private readonly deviceDetectorService = inject(DeviceDetectorService);
  private readonly http = inject(HttpClient);
  private readonly store = inject(Store);

  private readonly hasUserAgentData = 'userAgentData' in navigator;

  private readonly clientHints = this.store.selectSignal(
    DeviceGroupSelectors.selectClientHintsForDevice
  );

  readonly gadgetGuides = this.store.selectSignal(GadgetGuideSelectors.selectGadgetGuide);

  cameraKey: WritableSignal<{ cameraKey: string }> = signal({ cameraKey: '' });
  microphoneKey: WritableSignal<{ microphoneKey: string }> = signal({ microphoneKey: '' });
  speakerKey: WritableSignal<{ speakerKey: string }> = signal({ speakerKey: '' });

  getGadgetGuideByCameraKey = computed(() =>
    this.store.selectSignal(
      GadgetGuideSelectors.selectGadgetGuideBySha256(this.cameraKey()?.cameraKey)
    )
  );

  getGadgetGuideByMicrophoneKey = computed(() =>
    this.store.selectSignal(
      GadgetGuideSelectors.selectGadgetGuideBySha256(this.microphoneKey()?.microphoneKey)
    )
  );

  getGadgetGuideBySpeakerKey = computed(() =>
    this.store.selectSignal(
      GadgetGuideSelectors.selectGadgetGuideBySha256(this.speakerKey()?.speakerKey)
    )
  );

  filterClientHintsBrands(name, brands: Brand[]) {
    return brands.filter(({ brand }) => brand.toLowerCase().includes(name.toLowerCase()));
  }

  getBrowser(brands = []) {
    const [isChrome] = this.filterClientHintsBrands(BrowserFilterType.Chrome, brands);
    if (isChrome) {
      return { browserType: BrowserFilterType.Chrome, browserVersion: isChrome.version };
    }

    const [isEdge] = this.filterClientHintsBrands(BrowserFilterType.Edge, brands);
    if (isEdge) {
      return { browserType: BrowserFilterType.Edge, browserVersion: isEdge.version };
    }

    const { browser, browser_version } = this.deviceDetectorService;

    return {
      browserType: browser,
      browserVersion: browser_version,
    };
  }

  getMarkupForGadgetGuide(gadgetGuide: GadgetGuide, sha256: string, key: string) {
    let gadgetGuideRequest: GadgetGuideRequest;

    try {
      gadgetGuideRequest = gadgetGuide[sha256];
    } catch (e) {}

    if (gadgetGuideRequest?.response) {
      return (gadgetGuideRequest?.response)[key];
    }

    return '';
  }

  getSHA256 = async (val: string): Promise<string> => {
    const msgBuffer = new TextEncoder().encode(val);
    const hashBuffer = await globalThis.crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  };

  postGadgetGuideRequest(gadgetGuideRequest: GadgetAgent) {
    return this.http.post(`audioVideoCheck/gadgetguide`, gadgetGuideRequest);
  }

  async buildInitialRequestPayload(gadgetType: GadgetType) {
    // use client hints
    if (this.hasUserAgentData) {
      const { brands, mobile, model, platform, platformVersion } = this.clientHints() ?? {};
      const { browserType, browserVersion } = this.getBrowser(brands);

      const gadgetAgent = {
        browserType,
        browserVersion,
        deviceModel: model,
        deviceName: mobile === true ? 'Mobile' : 'Desktop',
        osType: platform,
        osVersion: platformVersion,
        gadgetType,
      };

      return {
        sha256: await this.getSHA256(JSON.stringify(gadgetAgent)),
        gadgetAgent,
      };
    }

    // use device detector service
    const { device, deviceType, os, os_version } = this.deviceDetectorService;
    const { browserType, browserVersion } = this.getBrowser();

    const gadgetAgent = {
      browserType: browserType,
      browserVersion: browserVersion,
      deviceModel: device,
      deviceName: deviceType === 'mobile' ? 'Mobile' : 'Desktop',
      osType: os,
      osVersion: os_version,
      gadgetType,
    };

    return {
      sha256: await this.getSHA256(JSON.stringify(gadgetAgent)),
      gadgetAgent,
    };
  }

  async initGadgetGuideRequest({ gadgetType }: { gadgetType: GadgetType }): Promise<GadgetGuide> {
    const { sha256, gadgetAgent } = await this.buildInitialRequestPayload(gadgetType);

    const existingState = this.store.selectSignal(
      GadgetGuideSelectors.selectGadgetGuideBySha256(sha256)
    )();

    const gadgetGuideRequest: GadgetGuideRequest = {
      gadgetAgent,
      response: '',
      status: 'init',
      error: '',
      ...existingState,
    };

    const gadgetGuide: GadgetGuide = { [sha256]: gadgetGuideRequest };
    this.store.dispatch(GadgetGuideActions.GadgetGuideRequest({ payload: gadgetGuide }));

    return gadgetGuide;
  }
}
