enum Feature {
  BypassRealId = 'BypassRealId',
  NewEndorsementApplication = 'NewEndorsementApplication',
  LogRocket = 'LogRocket',
  WaitingRoomPage = 'WaitingRoomPage',
  ExpereENoteSupport = 'ExpereENoteSupport',
  NeaSystemFields = 'NeaSystemFields',
  ClientHints = 'ClientHints',
  SigningRoomActionBlocking = 'SigningRoomActionBlocking',
  OptOutRefactor = 'OptOutRefactor',
  ChimeVideo = 'ChimeVideo',
  AVCheckPageV2 = 'AVCheckPageV2',
  NewSessionEndFailure = 'NewSessionEndFailure',
  AddUserToSession = 'AddUserToSession',
}

export { Feature };
