import { createAction, props } from '@ngrx/store';

import { GadgetGuideRequest as IGadgetGuideRequest } from 'src/app/features/gadget-guide/services/gadget-guide.service';

export const GadgetGuideRequest = createAction(
  '[Audo Video Check] GadgetGuide Request',
  props<{ payload: { [sha256: string]: IGadgetGuideRequest } }>()
);

export const GadgetGuideRequestSuccess = createAction(
  '[Audo Video Check] GadgetGuide Request Success',
  props<{ payload: { [sha256: string]: IGadgetGuideRequest }; response: any }>()
);

export const GadgetGuideRequestFailure = createAction(
  '[Audo Video Check] GadgetGuide Request Failure',
  props<{ payload: { [sha256: string]: IGadgetGuideRequest }; error: any }>()
);
