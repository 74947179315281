import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AttendeeResponse } from 'src/app/features/video-conference/chime/models/AttendeeResponse';
import { MeetingResponse } from 'src/app/features/video-conference/chime/models/MeetingResponse';

@Injectable({ providedIn: 'root' })
export class ChimeService {
  constructor(private readonly httpClient: HttpClient) {}

  // Meeting
  createMeeting(): Observable<MeetingResponse> {
    return this.httpClient.post<MeetingResponse>(`videoConference`, {}, );
  }

  getMeeting(): Observable<MeetingResponse> {
    return this.httpClient.get<MeetingResponse>(`videoConference`);
  }

  stopMeeting(): Observable<any> {
    return this.httpClient.delete<any>(`videoConference`);
  }

  // Attendee
  createAttendee(meetingId: string): Observable<AttendeeResponse> {
    return this.httpClient.post<AttendeeResponse>(`videoConference/attendee`, {meetingId: meetingId});
  }

  getAttendee(): Observable<AttendeeResponse> {
    return this.httpClient.get<AttendeeResponse>(`videoConference/attendee`);
  }

  //Recording
  startRecording(): Observable<any> {
    return this.httpClient.post<any>(`videoConferenceRecording`, {});
  }

  stopRecording(): Observable<any> {
    return this.httpClient.delete<any>(`videoConferenceRecording`, {});
  }

  getRecordingStatus(): Observable<any> {
    return this.httpClient.get<any>(`videoConferenceRecording/status`, {});
  }
}
